import axios from 'axios';
import { get } from 'lodash';
import { keysCamelToSnake } from './utils';

axios.interceptors.request.use(
    config => {
        const apiHost = process.env.REACT_APP_API_HOST || 'http://localhost:8000';
        const apiUrl = `${apiHost}/booking/v1`;

        config.url = apiUrl + config.url;
        config.headers.Accept = 'application/json';

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 404) {
            window.location.href = '/not-found';
        }
        return Promise.reject(error);
    }
);

export const fetchLocationEligibility = async ({ zipCode, partnerId }) => {
    try {
        let path = `/location-eligibility?zip_code=${zipCode}`;
        if (partnerId) {
            path += `&partner_id=${partnerId}`;
        }
        const result = await axios.get(path);
        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data.message', 'Failed to look up location'),
        };
    }
};

export const getPartnerProviders = async partnerId => {
    try {
        const path = `/providers?partner_id=${partnerId}`;
        const result = await axios.get(path);
        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data[0]', 'Failed to fetch providers'),
        };
    }
};

export const getVisitRequestLink = async shortVisitRequestUuid => {
    try {
        const path = `/visits/request/url/${shortVisitRequestUuid}`;
        const result = await axios.get(path);
        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data[0]', 'Failed to fetch visit request link.'),
        };
    }
};

export const uploadDocument = async ({ visitId, data }) => {
    try {
        const formData = new FormData();
        formData.append('file', data);
        formData.append('visit_id', visitId);
        const result = await axios.post(`/documents`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data.message', 'Failed to add to waitlist'),
        };
    }
};

export const fetchAvailableServices = async ({ zipCode, partnerId }) => {
    try {
        let requestUrl = `/services?zip_code=${zipCode}`;
        if (partnerId) {
            requestUrl += `&partner_id=${partnerId}`;
        }
        const result = await axios.get(requestUrl);
        // price returned in cents
        const resultWithDollars = result.data.map(service => ({
            ...service,
            price: service.price / 100,
            isGlobal: service.is_global,
        }));
        return { result: resultWithDollars };
    } catch (e) {
        return {
            error: get(e, 'response.data[0]', 'Failed to fetch services'),
        };
    }
};

export const getAvailability = async ({ services, zipCode, partnerId, visitId, patients }) => {
    try {
        const serviceIds = services.map(s => s.id);
        const result = await axios.post('/availability', {
            zip_code: zipCode,
            service_ids: serviceIds,
            partner_id: partnerId,
            visit_id: visitId,
            patients: patients,
        });
        return { result: result.data['availability'], helpText: result.data['help_text'] };
    } catch (e) {
        return {
            error: get(
                e,
                'response.data[0]',
                get(e, 'response.data.message', 'Failed to fetch availability')
            ),
        };
    }
};

export const getPreferredTimeWindows = async ({
    services,
    zipCode,
    partnerId,
    visitId,
    patients,
}) => {
    try {
        const serviceIds = services.map(s => s.id);
        const result = await axios.post('/preferred-time-window', {
            zip_code: zipCode,
            service_ids: serviceIds,
            partner_id: partnerId,
            visit_id: visitId,
            patients: patients,
        });
        return { result: result.data['preferred_windows'], helpText: result.data['help_text'] };
    } catch (e) {
        return {
            error: get(
                e,
                'response.data[0]',
                get(e, 'response.data.message', 'Failed to fetch availability')
            ),
        };
    }
};

export const scheduleVisit = async ({
    selectedProviderId,
    patients,
    address,
    visitTime,
    partnerId,
    providerNotes,
    visitRequestId,
    answers,
    visitMeta,
    patientMeta,
    clientPatientId,
}) => {
    try {
        const result = await axios.post(`/visits`, {
            patients: patients.map(p => keysCamelToSnake(p)),
            address: keysCamelToSnake(address),
            visit_time: [...visitTime][0],
            partner_id: partnerId,
            provider_id: selectedProviderId,
            notes: providerNotes,
            visit_request_id: visitRequestId,
            answers: answers.map(a => keysCamelToSnake(a)),
            visit_meta: visitMeta,
            patient_meta: patientMeta,
            external_patient_id: clientPatientId,
        });
        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data.message', 'Failed to schedule visit'),
        };
    }
};

export const requestVisit = async ({
    selectedProviderId,
    patients,
    address,
    visitTime,
    partnerId,
    providerNotes,
    answers,
    visitMeta,
    patientMeta,
    clientPatientId,
}) => {
    try {
        const result = await axios.post(`/request-visit`, {
            patients: patients.map(p => keysCamelToSnake(p)),
            address: keysCamelToSnake(address),
            requested_visit_times: Array.from(visitTime),
            partner_id: partnerId,
            provider_id: selectedProviderId,
            notes: providerNotes,
            answers: answers.map(a => keysCamelToSnake(a)),
            visit_meta: visitMeta,
            patient_meta: patientMeta,
            external_patient_id: clientPatientId,
        });
        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data.message', 'Failed to request visit'),
        };
    }
};

export const reviewVisit = async ({ visitId, rating, comments }) => {
    try {
        const result = await axios.post(`/visits/${visitId}/review`, {
            rating,
            comments,
        });
        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data.message', 'Failed to review visit'),
        };
    }
};

export const addNpstoVisit = async ({ visitId, nps }) => {
    try {
        const result = await axios.put(`/visits/${visitId}/review`, {
            nps,
        });
        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data.message', 'Failed to record nps'),
        };
    }
};

export const fetchVisitReviewMeta = async visitId => {
    try {
        const result = await axios.get(`/visits/${visitId}/review`);
        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data.message', 'Failed to review visit'),
        };
    }
};

export const fetchVisitDetails = async visitId => {
    try {
        const result = await axios.get(`/visits/${visitId}`);
        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data[0]', 'Failed to get visit'),
        };
    }
};

export const cancelVisit = async visitId => {
    try {
        const result = await axios.delete(`/visits/${visitId}`);
        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data[0]', 'Failed to cancel visit'),
        };
    }
};

export const rescheduleVisit = async ({ visitId, visitDatetime, address }) => {
    try {
        const result = await axios.put(`/visits/${visitId}`, {
            visit_datetime: visitDatetime,
            address: address,
        });
        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data[0]', 'Failed to reschedule visit'),
        };
    }
};

export const fetchPresetServices = async ({ zipCode, services, partnerId }) => {
    try {
        const result = await axios.get(
            `/preset-services?zip_code=${zipCode}&preset_services=${services}&partner_id=${partnerId}`
        );
        const resultWithCamel = result.data.map(service => ({
            ...service,
            price: service.price / 100,
            isGlobal: service.is_global,
        }));
        return { result: resultWithCamel };
    } catch (e) {
        return {
            error: get(e, 'response.data[0]', 'Failed to get services'),
        };
    }
};

export const fetchProfessional = async visitId => {
    try {
        const result = await axios.get(`/tip?visit_id=${visitId}`);

        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data[0]', 'Failed to fetch professional'),
        };
    }
};

export const fetchPartnerMeta = async partnerId => {
    try {
        const result = await axios.get(`/meta?partner_id=${partnerId}`);

        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data[0]', 'Failed to fetch partner meta'),
        };
    }
};

export const fetchQuestions = async partnerId => {
    try {
        const result = await axios.get(`/questions?partner_id=${partnerId}`);

        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data[0]', 'Failed to fetch questions'),
        };
    }
};

export const fetchQhpQuestions = async visitId => {
    try {
        const result = await axios.get(`/visits/${visitId}/intake-form`);

        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data[0]', 'Failed to fetch questions'),
        };
    }
};

export const saveQhpAnswers = async ({ visitId, answers }) => {
    try {
        const result = await axios.post(`/visits/${visitId}/intake-form`, {
            answers: answers.map(a => keysCamelToSnake(a)),
        });
        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data.message', 'Failed to save answers'),
        };
    }
};

export const fetchSupportedLanguages = async () => {
    try {
        const result = await axios.get('/languages');

        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data[0]', 'Failed to fetch supported languages'),
        };
    }
};

export const accessDocument = async ({ docId, dob, firstName, lastName }) => {
    try {
        const result = await axios.post(`/documents/${docId}`, {
            dob: dob,
            first_name: firstName,
            last_name: lastName,
        });
        return { result: result.data };
    } catch (e) {
        return {
            error: get(e, 'response.data.message', 'Unable to fetch document'),
        };
    }
};
