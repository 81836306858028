import { makeStyles } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';
import React from 'react';
import AxleAccordionContainer from '../../components/AxleAccordionContainer';
import AxleTimesgrid from '../../components/AxleTimesgrid';
import { AddressContainer } from '../../components/Book';

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            '& .MuiAccordionDetails-root': {
                padding: 0,

                '& > .MuiBox-root': {
                    padding: 0,
                    '& .subTitle': {
                        paddingBottom: 0,
                        '& > p': {
                            paddingLeft: theme.spacing(6),
                            paddingRight: theme.spacing(2),
                        },
                    },
                },
            },
        },
    },
    scheduleButton: {
        width: '400px',
        [theme.breakpoints.down('md')]: {
            width: '300px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '250px',
        },
    },
    scheduleButtonContainer: {
        marginTop: theme.spacing(2),
    },
}));

export default function ScheduleContainerScheduling({
    address,
    validZipCodes,
    validateAndSetState,
    isLoadingTimesgrid,
    isServiceSelected,
    visitTime,
    onDatetimeSelected,
    availability,
    tier,
    helpText,
    whereRef,
}) {
    const classes = useStyles();

    var noAvailabilityText = '';
    var noAvailabilityAction = null;

    let scheduleSubTitle;
    if (tier === 1) {
        scheduleSubTitle = 'Select the time and date for your visit.';
    } else if (tier === 2) {
        scheduleSubTitle = `Select the time and date for your visit. The time you select is not guaranteed. A member of our team will reach out to confirm your visit time.`;
    } else {
        scheduleSubTitle = `Select your date and time preferences. The time and date you select are not guaranteed. A member of our team will reach out to confirm your visit time.`;
    }

    const getStateSelectValue = state => {
        if (!state || typeof state !== 'string') {
            return '';
        } else {
            return state;
        }
    };

    const isZipcodeInServiceArea = zipcode => {
        if (zipcode.length == 5 && !validZipCodes.includes(zipcode)) {
            return false;
        } else {
            return true;
        }
    };

    const handleAddressChange = async ({ value, field }) => {
        const newAddress = { ...address, [field]: value };
        const isValidZipcode =
            newAddress.zipCode.length === 5 && isZipcodeInServiceArea(newAddress.zipCode);
        validateAndSetState({ address: newAddress, isValidZipcode: isValidZipcode });
    };

    // placeholder function
    const handleDisabledFieldClick = () => {};

    return (
        <>
            <AddressContainer
                address={address}
                getStateSelectValue={getStateSelectValue}
                sectionTitle={'Confirm Your Address'}
                validateZipCodes={isZipcodeInServiceArea}
                handleAddressChange={handleAddressChange}
                onDisabledFieldClicked={handleDisabledFieldClick}
                disableCityStateZip={false}
                sectionRef={whereRef}
            />
            <AxleAccordionContainer
                title='Schedule your at-home visit'
                subTitle={scheduleSubTitle}
                isLoading={isLoadingTimesgrid}
                icon={<ScheduleIcon fontSize='small' color='primary' />}
                className={classes.root}
            >
                <AxleTimesgrid
                    isLoading={isLoadingTimesgrid}
                    disabled={!address.zipCode || !isServiceSelected}
                    availability={availability}
                    onDatetimeSelected={onDatetimeSelected}
                    visitTime={visitTime}
                    noAvailabilityText={noAvailabilityText}
                    noAvailabilityAction={noAvailabilityAction}
                    helpText={helpText}
                    isAvailabilityTimeWindow={tier === 3}
                />
            </AxleAccordionContainer>
        </>
    );
}
