import { TextField, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DirectionArrows from '../assets/directionArrows.png';
import { AxleButton, AxleCardContainer } from '../components';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        maxWidth: '100%',
        boxShadow: `inset 0px 4px 0px ${theme.palette.primary.main}`,
        border: '1px solid #E8E6E1',
        borderRadius: '8px',
        [theme.breakpoints.up('md')]: {
            maxWidth: '50%',
            margin: '0 auto',
            marginTop: '64px',
        },
    },
    textField: {
        width: '400px',
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            width: '250px',
        },
    },
    cta: {
        width: '400px',
        [theme.breakpoints.down('md')]: {
            width: '250px',
        },
    },
    img: {
        width: '160px',
    },
});

class Waitlist extends React.Component {
    state = {
        isLoading: false,
        isSuccessful: false,
        email: '',
    };

    handleNotifyClick = async () => {
        this.setState({ isLoading: false, isSuccessful: true });
    };

    handleEmailChange = e => {
        const email = e && e.target ? e.target.value : '';
        this.setState({ email });
    };

    render() {
        const { classes } = this.props;
        const { isSuccessful, isLoading } = this.state;
        return (
            <AxleCardContainer>
                <img className={classes.img} src={DirectionArrows} />
                <Typography variant='h4' gutterBottom>
                    <b>
                        {isSuccessful
                            ? `We'll let you know when we begin operating in ${this.props.zipCode}!`
                            : `We don't serve ${this.props.zipCode} yet`}
                    </b>
                </Typography>
                {!isSuccessful && (
                    <div>
                        <Typography variant='h6' gutterBottom>
                            {`Leave your email address and we'll drop you a line when we're live in your area.`}
                        </Typography>
                        <TextField
                            className={classes.textField}
                            inputProps={{ style: { textAlign: 'center' } }}
                            variant='outlined'
                            placeholder='Email'
                            onChange={this.handleEmailChange}
                        />
                        <AxleButton
                            className={classes.cta}
                            onClick={this.handleNotifyClick}
                            size='large'
                            isLoading={isLoading}
                        >
                            Notify me!
                        </AxleButton>
                    </div>
                )}
            </AxleCardContainer>
        );
    }
}

const mapStateToProps = state => ({
    zipCode: state.zipCode,
});

export default withRouter(connect(mapStateToProps, null)(withStyles(styles)(Waitlist)));
