import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Hidden,
    Snackbar,
    Typography,
    withStyles,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { find, get, isEmpty, omit } from 'lodash';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    fetchAvailableServices,
    fetchLocationEligibility,
    fetchPresetServices,
    fetchQuestions,
    fetchSupportedLanguages,
    getAvailability,
    getPartnerProviders,
    getPreferredTimeWindows,
    requestVisit,
    scheduleVisit,
    uploadDocument,
} from '../api';
import { AxleButton, AxlePaymentBox } from '../components';
import {
    AddressContainer,
    PersonalInfoContainer,
    QuestionContainer,
    ServiceContainer,
} from '../components/Book';
import NoteContainer from '../components/Book/NoteContainer';
import ProviderContainer from '../components/Book/ProviderContainer';
import ScheduleContainer from '../components/Book/ScheduleContainer';
import UploadContainer from '../components/Book/UploadContainer';
import { ValidateMessages, allUSStates } from '../constants';
import { keysSnakeToCamel, validateEmail, validatePhone } from '../utils';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    leftContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 7,
        padding: theme.spacing(2), // Default the whole page to have some padding
        paddingTop: theme.spacing(5), // But override the top padding to be a bit larger.
        borderRight: '1px dashed lightgray',
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            borderRight: '0',
            borderBottom: '1px dashed lightgray',
        },
    },
    rightContainer: {
        display: 'flex',
        flex: 3,
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            alignItems: 'center',
            flex: 0,
        },
    },
    paymentBox: {
        marginRight: theme.spacing(4),
        marginTop: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
        },
    },
    payCta: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            width: '100%',
            position: 'fixed',
            height: '48px',
            bottom: 0,
            left: 0,
            zIndex: 9998,
        },
    },
    snackbar: {
        zIndex: 9999,
    },
});

class Book extends React.Component {
    state = {
        patients: [
            {
                // Don't change id: 0; api relies on that to delineate between parent & child visit
                id: 0,
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                dob: null,
                sex: '',
                // services selected by patient
                services: [],
                preferredLanguage: 'en',
            },
        ],
        address: {
            address1: '',
            address2: '',
            city: this.props.city || '',
            state: this.props.usState || '',
            zipCode: this.props.zipCode || '',
        },
        // all available services
        services: [],
        availability: {},
        visitTime: new Set(),
        isLoadingTimesgrid: false,
        isPaymentLoading: false,
        snackbar: { show: false, message: '', severity: 'error' },
        areServicesPreset: false,
        files: [],
        providers: [],
        selectedProviderId: '',
        providerNotes: '',
        questions: [],
        answers: [],
        supportedLanguages: [],
        formErrors: {},
        showDisabledAddressPopup: false,
        visitMeta: {},
        patientMeta: {},
        clientPatientId: '',
        tier: this.props.tier,
    };

    constructor(props) {
        super(props);
        this.whoRef = React.createRef();
        this.whatRef = React.createRef();
        this.whereRef = React.createRef();
    }

    async componentDidMount() {
        // If the user is coming from a partner site where the services have been chosen already
        // then we just want to fetch those from the api
        const presetPatientServices = [];
        if (
            this.props.presetServices &&
            this.props.zipCode !== undefined &&
            !this.props.visitRequestId
        ) {
            const { result, error } = await fetchPresetServices({
                zipCode: this.props.zipCode,
                services: this.props.presetServices,
                partnerId: this.props.partnerId,
            });
            if (result) {
                // First pass preset services will only support a single patient
                const patient = this.state.patients[0];
                // Set preset services as selected and they'll be disabled
                for (const preset of result) {
                    presetPatientServices.push(preset);
                    this.handleServiceSelected({
                        patient,
                        service: preset,
                        checked: true,
                    });
                }
                this.setState({ services: result, areServicesPreset: true });
            } else {
                this.setState({
                    snackbar: { show: true, message: error, severity: 'error' },
                });
            }
        } else {
            const { result, error } = await fetchAvailableServices({
                zipCode: this.props.zipCode,
                partnerId: this.props.partnerId,
            });
            if (result) {
                this.setState({ services: result });
            } else {
                this.setState({
                    snackbar: { show: true, message: error, severity: 'error' },
                });
            }
        }

        if (this.props.partnerMeta && this.props.partnerMeta.allows_provider_selection) {
            const providersResult = await getPartnerProviders(this.props.partnerId);
            if (providersResult && providersResult.result) {
                this.setState({ providers: providersResult.result.providers });
            }

            if (this.props.providerId) {
                this.setState({ selectedProviderId: this.props.providerId });
            }
        }

        const [questionsResponse, languagesResponse] = await Promise.all([
            fetchQuestions(this.props.partnerId),
            fetchSupportedLanguages(),
        ]);
        if (questionsResponse && questionsResponse.result) {
            this.setState({ questions: questionsResponse.result });
        }
        if (languagesResponse && languagesResponse.result) {
            this.setState({ supportedLanguages: languagesResponse.result });
        }

        // Check if pre-filled patient/address data has been passed in the query params
        // e.g. from Create Visit button in partner app
        this.setContentFromQueryParams();
    }

    setContentFromQueryParams = () => {
        // Right now we support up to 5 patients with pre-filled info in request params.
        // For customer ease-of-use, we have name each field to correspond to each number of patients.
        // Ex. firstName1, firstName2.  We clean up the number from the field name to match our state.
        const prefilledPatientInfo = new Set([
            'firstName',
            'lastName',
            'dob',
            'phone',
            'email',
            'sex',
        ]);
        const prefilledPatientInfo2 = new Set([
            'firstName2',
            'lastName2',
            'dob2',
            'phone2',
            'email2',
            'sex2',
        ]);
        const prefilledPatientInfo3 = new Set([
            'firstName3',
            'lastName3',
            'dob3',
            'phone3',
            'email3',
            'sex3',
        ]);
        const prefilledPatientInfo4 = new Set([
            'firstName4',
            'lastName4',
            'dob4',
            'phone4',
            'email4',
            'sex4',
        ]);
        const prefilledPatientInfo5 = new Set([
            'firstName5',
            'lastName5',
            'dob5',
            'phone5',
            'email5',
            'sex5',
        ]);
        const addressContent = new Set(['address1', 'address2', 'city', 'state', 'zipCode']);
        const params = keysSnakeToCamel(queryString.parse(this.props.location.search));

        this.setState({ visitMeta: params?.visitMeta });
        this.setState({ patientMeta: params?.patientMeta });
        this.setState({ clientPatientId: params?.clientPatientId });

        if (Object.keys(params).length) {
            const patients = [];
            const address = this.state.address;

            for (const [key, value] of Object.entries(params)) {
                if (prefilledPatientInfo.has(key)) {
                    const cleanKey = key.replace(/[0-9]/g, '');
                    if (!patients.includes(this.state.patients[0])) {
                        patients.push(this.state.patients[0]);
                    }
                    patients[0][cleanKey] = value;
                }
                if (prefilledPatientInfo2.has(key)) {
                    const cleanKey = key.replace(/[0-9]/g, '');
                    if (!patients.includes(this.state.patients[1])) {
                        // The checks below address the scenario where a query parameter
                        // for a patient might be missing when they have not yet been
                        //instantiated within our state array. It ensures that we do not
                        //inadvertently add a patient to the state array if there are technically
                        //preceding patients whose parameters are missing from the query.
                        //This helps maintain the integrity of the patient parameters and
                        //prevents potential inconsistencies.

                        if (!patients.includes(this.state.patients[0])) {
                            this.handleAddPatientClick();
                            patients.push(this.state.patients[0]);
                        }
                        this.handleAddPatientClick();
                        patients.push(this.state.patients[1]);
                    }
                    patients[1][cleanKey] = value;
                }
                if (prefilledPatientInfo3.has(key)) {
                    const cleanKey = key.replace(/[0-9]/g, '');
                    if (!patients.includes(this.state.patients[2])) {
                        if (!patients.includes(this.state.patients[0])) {
                            this.handleAddPatientClick();
                            patients.push(this.state.patients[0]);
                        }
                        if (!patients.includes(this.state.patients[1])) {
                            this.handleAddPatientClick();
                            patients.push(this.state.patients[1]);
                        }
                        this.handleAddPatientClick();
                        patients.push(this.state.patients[2]);
                    }
                    patients[2][cleanKey] = value;
                }
                if (prefilledPatientInfo4.has(key)) {
                    const cleanKey = key.replace(/[0-9]/g, '');
                    if (!patients.includes(this.state.patients[3])) {
                        if (!patients.includes(this.state.patients[0])) {
                            this.handleAddPatientClick();
                            patients.push(this.state.patients[0]);
                        }
                        if (!patients.includes(this.state.patients[1])) {
                            this.handleAddPatientClick();
                            patients.push(this.state.patients[1]);
                        }
                        if (!patients.includes(this.state.patients[2])) {
                            this.handleAddPatientClick();
                            patients.push(this.state.patients[2]);
                        }
                        this.handleAddPatientClick();
                        patients.push(this.state.patients[3]);
                    }
                    patients[3][cleanKey] = value;
                }
                if (prefilledPatientInfo5.has(key)) {
                    const cleanKey = key.replace(/[0-9]/g, '');
                    if (!patients.includes(this.state.patients[4])) {
                        if (!patients.includes(this.state.patients[0])) {
                            this.handleAddPatientClick();
                            patients.push(this.state.patients[0]);
                        }
                        if (!patients.includes(this.state.patients[1])) {
                            this.handleAddPatientClick();
                            patients.push(this.state.patients[1]);
                        }
                        if (!patients.includes(this.state.patients[2])) {
                            this.handleAddPatientClick();
                            patients.push(this.state.patients[2]);
                        }
                        if (!patients.includes(this.state.patients[3])) {
                            this.handleAddPatientClick();
                            patients.push(this.state.patients[3]);
                        }
                        this.handleAddPatientClick();
                        patients.push(this.state.patients[4]);
                    }
                    patients[4][cleanKey] = value;
                }

                // If there are no patients added to the params, add a default
                // patient to avoid bugs and to allow the form to render properly.
                if (patients.length === 0) {
                    this.handleAddPatientClick();
                    patients.push(this.state.patients[0]);
                }

                // We have to check that each expected state field either has a
                // value or is set to an empty string. Otherwise, the state will
                // be undefined and the form will not render properly.

                for (const patient of patients) {
                    if (patient.firstName == undefined) patient.firstName = '';
                    if (patient.lastName == undefined) patient.lastName = '';
                    if (patient.email == undefined) patient.email = '';
                    if (patient.phone == undefined) patient.phone = '';
                    if (patient.dob == undefined) patient.dob = null;
                    if (patient.services == undefined) patient.services = [];
                    if (patient.preferredLanguage == undefined) patient.preferredLanguage = '';
                }

                if (addressContent.has(key)) {
                    address[key] = params[key];
                }
            }
            this.setState({ patients: patients, address });
            if (
                this.state.areServicesPreset === true &&
                this.allPatientsComplete(this.state.patients)
            ) {
                this.handleShowTimesgrid();
            }
        }

        // NOTE: IF you are coming to this page but bypassing the location eligibility
        //  check, then this query parameter is functionally required, otherwise, nothing
        //  will work as expected.
        const queryParamZipCode = params.zipCode;
        if (queryParamZipCode) {
            fetchLocationEligibility({
                zipCode: queryParamZipCode,
                partnerId: params.partnerId,
            }).then(response => {
                this.setState({ tier: response.result.tier });

                // WEIRD STATE ISSUES ALERT:
                //  First off -- we should have hooks that update everything as each individual
                //  piece of the state changes. We'll get to it later.
                //  Second off -- the handleAddressChange doesn't work here for some reason, even though it
                //  does work in other places?? Idk, not gonna fight it rn.
                let address = this.state.address;
                address['city'] = response.result.city;
                address['state'] = response.result.us_state;
                this.setState({ address });

                this.handleShowTimesgrid();
            });
        }
    };

    handlePatientInfoFieldChange = ({ value, id, field }) => {
        const patients = this.state.patients;
        const updatedPatients = [];
        for (const patient of patients) {
            if (id === patient.id) {
                let newVal = value;
                if (field === 'phone') {
                    newVal = value.replace(/\D/g, '');
                }
                patient[field] = newVal;
            }
            updatedPatients.push(patient);
        }

        if (this.state.areServicesPreset === true && this.allPatientsComplete(patients)) {
            this.handleShowTimesgrid();
        }

        this.setState({ patients: updatedPatients }, () => {
            if (field === 'phone') {
                this.validatePhoneNumber();
            } else if (get(this.state.formErrors, `patients[${id}][${field}]`)) {
                this.validateForm();
            }
        });
    };

    handleAddPatientClick = () => {
        const patients = this.state.patients;
        const nextPatientId = patients.length;
        patients.push({
            id: nextPatientId,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            sex: '',
            dob: null,
            services: [],
        });
        this.setState({ patients });
    };

    handleRemovePatientClick = () => {
        const patients = this.state.patients;
        patients.pop();
        this.setState({ patients });
    };

    isPatientComplete = patient => {
        const { phone, firstName, lastName, dob, sex } = patient;
        if (patient.id === 0) {
            return phone && firstName && lastName && sex && dob && dob !== 'Invalid date';
        } else {
            return firstName && lastName && phone && sex && dob && dob !== 'Invalid date';
        }
    };

    handleServiceSelected = async ({ patient, service, checked }) => {
        let patientServices = patient.services;
        const updatedPatients = [];
        if (checked) {
            patientServices.push(service);
        } else {
            patientServices = patientServices.filter(ps => ps.id !== service.id);
        }

        for (const p of this.state.patients) {
            if (p.id === patient.id) {
                p['services'] = patientServices;
            }
            updatedPatients.push(p);
        }

        this.setState({ patients: updatedPatients }, () => {
            if (
                this.state.address.zipCode &&
                this.isServiceSelected(this.state.patients) &&
                this.allPatientsComplete(this.state.patients)
            ) {
                this.handleShowTimesgrid();
            } else {
                this.setState({
                    availability: {},
                });
            }
        });
    };

    allPatientsComplete = patients => {
        for (const p of patients) {
            if (!this.isPatientComplete(p)) {
                return false;
            }
        }

        return true;
    };

    handleAddressChange = ({ value, field }) => {
        const address = this.state.address;
        address[field] = value;
        this.setState({ address }, () => {
            if (field == 'zipCode' && this.isServiceSelected(this.state.patients)) {
                this.handleShowTimesgrid();
            }
        });
    };

    handleDisabledAddressFieldClick = () => {
        this.setState({ showDisabledAddressPopup: true });
    };

    getStateSelectValue = state => {
        if (!state) {
            return '';
        }
        // If the state abbrev is already being returned then use that otherwise
        // we need to resolve the abbrev from the state's full name
        if (state.length === 2) {
            return state.toUpperCase();
        } else {
            const theState = find(allUSStates, s => {
                return s.verbose.toLowerCase() === state.toLowerCase();
            });
            return theState ? theState.abbrev : '';
        }
    };

    handleDatetimeSelected = newTimeSelection => {
        let currentlySelectedTimes = this.state.visitTime;
        if (this.state.tier === 3) {
            currentlySelectedTimes.has(newTimeSelection)
                ? currentlySelectedTimes.delete(newTimeSelection)
                : currentlySelectedTimes.add(newTimeSelection);
        } else {
            currentlySelectedTimes = new Set();
            currentlySelectedTimes.add(newTimeSelection);
        }
        this.setState({ visitTime: currentlySelectedTimes });
    };

    handleShowTimesgrid = async () => {
        const zipCode = this.state.address.zipCode;
        // NOTE: The tier is set after the location eligibility check. Logically, we shouldn't be able
        //  to check for available times, if we don't yet know whether or not the zip code is serviced.
        //  Maybe we make a "didPassLocationEligibilityCheck" but I don't want to add anything new in until
        //  we've fixed the rest of this product.
        if (
            zipCode &&
            zipCode.length > 1 &&
            !!this.state.tier &&
            this.isServiceSelected(this.state.patients)
        ) {
            this.setState({ isLoadingTimesgrid: true });
            const services = this.state.patients.map(p => p.services).flat();

            let result, helpText, error;
            if (this.state.tier === 1 || this.state.tier === 2) {
                ({ result, helpText, error } = await getAvailability({
                    services,
                    zipCode,
                    partnerId: this.props.partnerId,
                    patients: this.state.patients,
                }));
            } else {
                ({ result, helpText, error } = await getPreferredTimeWindows({
                    services,
                    zipCode,
                    partnerId: this.props.partnerId,
                    patients: this.state.patients,
                }));
            }

            if (result) {
                this.setState({
                    availability: result,
                    helpText: helpText,
                    isLoadingTimesgrid: false,
                });
            } else {
                this.setState({
                    isLoadingTimesgrid: false,
                    snackbar: { show: true, message: error, severity: 'error' },
                });
            }
        } else {
            this.setState({
                availability: {},
            });
        }
    };

    isServiceSelected = patients => {
        let serviceSelected = true;
        for (const patient of patients) {
            if (patient.services.length === 0) {
                serviceSelected = false;
                break;
            }
        }

        return serviceSelected;
    };

    isAddressComplete = address => {
        return address.address1 && address.city && address.state && address.zipCode ? true : false;
    };

    areRequiredQuestionsAnswered = () => {
        const { questions, answers } = this.state;
        const answeredQuestionIds = new Set();
        for (const a of answers) {
            answeredQuestionIds.add(a.questionId);
        }

        for (const q of questions) {
            if (q.is_required) {
                if (!answeredQuestionIds.has(q.id)) {
                    return false;
                }
            }
        }

        return true;
    };

    handleUpload = files => {
        this.setState({ files });
    };

    handlePaymentClick = async () => {
        this.setState({ isPaymentLoading: true });
        const {
            patients,
            address,
            visitTime,
            files,
            selectedProviderId,
            providerNotes,
            answers,
            tier,
        } = this.state;

        let error, result;
        if (tier === 3) {
            ({ result, error } = await requestVisit({
                selectedProviderId,
                patients,
                address,
                visitTime,
                providerNotes,
                partnerId: this.props.partnerId,
                visitRequestId: this.props.visitRequestId,
                answers,
                visitMeta: this.state.visitMeta,
                patientMeta: this.state.patientMeta,
                clientPatientId: this.state.clientPatientId,
            }));
        } else {
            ({ result, error } = await scheduleVisit({
                selectedProviderId,
                patients,
                address,
                visitTime,
                providerNotes,
                partnerId: this.props.partnerId,
                visitRequestId: this.props.visitRequestId,
                answers,
                visitMeta: this.state.visitMeta,
                patientMeta: this.state.patientMeta,
                clientPatientId: this.state.clientPatientId,
            }));
        }

        if (result) {
            if (files.length) {
                await uploadDocument({
                    visitId: result.visit_id,
                    data: files[0],
                });
            }
            this.props.history.push(`/confirmation/${result.visit_id}`);
        } else {
            this.setState({
                isPaymentLoading: false,
                snackbar: { show: true, message: error, severity: 'error' },
            });
        }
    };

    canProceedToPayment = () => {
        const { visitTime, patients, address } = this.state;
        const isServiceSelected = this.isServiceSelected(patients);
        const hasVisitTime = visitTime.size >= 1;
        const isPatientComplete = this.allPatientsComplete(patients);
        return (
            isServiceSelected &&
            hasVisitTime &&
            isPatientComplete &&
            this.isAddressComplete(address) &&
            this.areRequiredQuestionsAnswered() &&
            isEmpty(this.state.formErrors)
        );
    };

    getLineItems = () => {
        const { patients, services } = this.state;
        const lineItems = [];

        // First add the services patient selected then also add the global visit service
        for (const patient of patients) {
            lineItems.push(patient.services);
        }
        for (const service of services) {
            if (service.isGlobal) {
                lineItems.push(service);
            }
        }

        const flatLineItems = lineItems.flat();

        const quantities = {};
        for (const li of flatLineItems) {
            const id = li.id;
            if (id in quantities) {
                const q = quantities[id];
                quantities[id] = q + 1;
            } else {
                quantities[id] = 1;
            }
        }

        const lineItemsWithQuantity = [];
        const added = new Set();
        for (const lineItem of flatLineItems) {
            if (!added.has(lineItem.id)) {
                added.add(lineItem.id);
                lineItemsWithQuantity.push({
                    ...lineItem,
                    // This list is ultimately passed to <AxlePaymentBox> which expects price
                    //  to be one of the attributes. Removing price from the line item object
                    //  does NOT seem to break anything but leaving here just in case. It will
                    //  always be 0 since we removed payment functionality.
                    price: 0,
                    quantity: quantities[lineItem.id],
                });
            }
        }
        return lineItemsWithQuantity;
    };

    handleCloseSnackbar = () => {
        this.setState({
            snackbar: { show: false, message: '', severity: 'error' },
        });
    };

    handleQuestionResponse = ({ questionId, value }) => {
        const { answers } = this.state;
        const updatedAnswers = [];
        updatedAnswers.push({ questionId, value });
        for (const a of answers) {
            // If the new answers is updating an existing one then don't include the existing one
            if (a.questionId !== questionId) {
                updatedAnswers.push(a);
            }
        }
        this.setState({ answers: updatedAnswers }, () => {
            if (get(this.state.formErrors, `questions[${questionId}]`)) {
                this.validateForm();
            }
        });
    };

    validateForm = () => {
        let errors = {};
        let hasErrors = false;
        const requiredMsg = ValidateMessages.required;

        errors.patients = {};
        this.state.patients.map(p => {
            errors.patients[p.id] = {};

            if (p.email && !validateEmail(p.email)) {
                errors.patients[p.id]['email'] = ValidateMessages.email;
            }
            if (!p.firstName) {
                errors.patients[p.id]['firstName'] = requiredMsg;
            }
            if (!p.lastName) {
                errors.patients[p.id]['lastName'] = requiredMsg;
            }
            if (!p.phone) {
                errors.patients[p.id]['phone'] = requiredMsg;
            } else if (!validatePhone(p.phone)) {
                errors.patients[p.id]['phone'] = ValidateMessages.phone;
            }
            if (!p.sex) {
                errors.patients[p.id]['sex'] = requiredMsg;
            }
            if (!p.dob || p.dob == 'Invalid date') {
                errors.patients[p.id]['dob'] = requiredMsg;
            }

            if (!isEmpty(errors.patients[p.id])) {
                hasErrors = true;
            }
        });

        if (this.state.questions.length) {
            errors.questions = {};
            this.state.questions.map(x => {
                const answer = this.state.answers.find(a => a.questionId == x.id);
                if (x.is_required && (!answer || !answer.value)) {
                    errors.questions[`${x.id}`] = requiredMsg;
                    hasErrors = true;
                }
            });
        }

        this.setState({ formErrors: hasErrors ? errors : {} });
    };

    validatePhoneNumber = () => {
        let errors = this.state.formErrors;
        let hasError = false;

        errors.patients = errors.patients || {};
        this.state.patients.map(p => {
            errors.patients[p.id] = errors.patients[p.id] || {};

            if (p.phone && !validatePhone(p.phone)) {
                errors.patients[p.id]['phone'] = ValidateMessages.phone;
            } else if (errors.patients[p.id]['phone']) {
                errors.patients[p.id] = omit(errors.patients[p.id], ['phone']);
            }

            if (!isEmpty(errors.patients[p.id])) {
                hasError = true;
            }
        });
        if (!isEmpty(errors.questions)) {
            hasError = true;
        }

        this.setState({ formErrors: hasError ? errors : {} });
    };

    // placeholder function
    validateZipCodes = zipcode => {
        return true;
    };

    getContainers = () => {
        const params = keysSnakeToCamel(queryString.parse(this.props.location.search));
        const { partnerMeta } = this.props;
        const {
            patients,
            areServicesPreset,
            formErrors,
            services,
            address,
            isLoadingTimesgrid,
            availability,
            visitTime,
            providers,
            selectedProviderId,
            questions,
            supportedLanguages,
            tier,
        } = this.state;
        const containers = [];

        if (params?.hidePatientInput !== 'true') {
            containers.push({
                renderFunc: idx => (
                    <PersonalInfoContainer
                        key={idx}
                        patients={patients}
                        areServicesPreset={areServicesPreset}
                        handleAddPatientClick={this.handleAddPatientClick}
                        handlePatientInfoFieldChange={this.handlePatientInfoFieldChange}
                        handleRemovePatientClick={this.handleRemovePatientClick}
                        errors={formErrors.patients}
                        supportedLanguages={supportedLanguages}
                        sectionRef={this.whoRef}
                    />
                ),
            });
        }

        if (params?.hideServiceInput !== 'true') {
            containers.push({
                renderFunc: idx => (
                    <ServiceContainer
                        key={idx}
                        patients={patients}
                        services={services}
                        areServicesPreset={areServicesPreset}
                        isPaid={true}
                        handleServiceSelected={this.handleServiceSelected}
                        sectionRef={this.whatRef}
                        whoRef={this.whoRef}
                    />
                ),
            });
        }

        if (params?.hideAddressInput !== 'true') {
            containers.push({
                renderFunc: idx => (
                    <AddressContainer
                        key={idx}
                        address={address}
                        sectionTitle={'Where?'}
                        validateZipCodes={this.validateZipCodes}
                        getStateSelectValue={this.getStateSelectValue}
                        handleAddressChange={this.handleAddressChange}
                        onDisabledFieldClicked={this.handleDisabledAddressFieldClick}
                        disableCityStateZip
                        sectionRef={this.whereRef}
                    />
                ),
            });
        }

        containers.push({
            renderFunc: idx => (
                <ScheduleContainer
                    key={idx}
                    address={address}
                    patient={patients[0]}
                    isLoadingTimesgrid={isLoadingTimesgrid}
                    isServiceSelected={this.isServiceSelected(patients)}
                    visitTime={visitTime}
                    onDatetimeSelected={this.handleDatetimeSelected}
                    availability={availability}
                    whoRef={this.whoRef}
                    whatRef={this.whatRef}
                    whereRef={this.whereRef}
                    tier={tier}
                    helpText={this.state.helpText}
                />
            ),
        });

        if (partnerMeta && partnerMeta.allows_provider_selection) {
            containers.push({
                renderFunc: idx => (
                    <ProviderContainer
                        key={idx}
                        providerId={this.props.providerId}
                        providers={providers}
                        selectedProviderId={selectedProviderId}
                        onChangeProvider={e =>
                            this.setState({
                                selectedProviderId: e.target ? e.target.value : null,
                            })
                        }
                    />
                ),
            });
        }

        if (partnerMeta && partnerMeta.allows_document_upload) {
            containers.push({
                renderFunc: idx => <UploadContainer key={idx} handleUpload={this.handleUpload} />,
            });
        }
        if (partnerMeta && partnerMeta.allows_notes_for_qhp) {
            containers.push({
                renderFunc: idx => (
                    <NoteContainer
                        key={idx}
                        value={this.state.providerNotes}
                        onChange={notes => this.setState({ providerNotes: notes })}
                    />
                ),
            });
        }

        if (this.state.questions.length) {
            containers.push({
                renderFunc: idx => (
                    <QuestionContainer
                        key={idx}
                        questions={questions}
                        handleQuestionResponse={this.handleQuestionResponse}
                        errors={formErrors.questions || {}}
                    />
                ),
            });
        }
        return containers;
    };

    renderDisabledAddressPopup = () => {
        const { classes } = this.props;
        const { showDisabledAddressPopup } = this.state;
        return showDisabledAddressPopup ? (
            <Dialog
                onClose={() => this.setState({ showDisabledAddressPopup: false })}
                open={showDisabledAddressPopup}
                style={{ padding: 10 }}
            >
                <DialogTitle>Want to change your address?</DialogTitle>
                <DialogContent>
                    <Typography variant='subtitle2'>
                        We&apos;ve pre-filled your city, state, and zip code for you. If you&apos;d
                        like to change your city, state, or zip code then you can click the browser
                        back button to input your new zip code. Otherwise you can scroll down to
                        select a time.
                    </Typography>
                </DialogContent>
            </Dialog>
        ) : null;
    };

    render() {
        const { classes } = this.props;
        const lineItems = this.getLineItems();
        // Previously, patients could pay for services (via Stripe) but this functionality has been
        //  removed. The orderTotal is now always 0.
        const orderTotal = 0;
        const containers = this.getContainers();
        const showBookVisitBtn = this.state.areServicesPreset || !orderTotal;

        return (
            <div className={classes.root}>
                <div className={classes.leftContainer}>
                    {containers.map((container, i) => container.renderFunc(i))}
                </div>
                {this.renderDisabledAddressPopup()}
                {/* Shown on desktop */}
                <div className={classes.rightContainer}>
                    <AxlePaymentBox
                        className={classes.paymentBox}
                        ctaTitle='Book Visit'
                        title='Visit Summary'
                        lineItems={lineItems}
                        supportEmail={this.props.partnerMeta.support_email}
                        onClick={this.handlePaymentClick}
                        orderTotal={orderTotal}
                        disabled={!this.canProceedToPayment()}
                        isLoading={this.state.isPaymentLoading}
                        buttonTooltip={
                            showBookVisitBtn
                                ? 'All fields must be completed before booking your visit'
                                : ''
                        }
                        validateForm={this.validateForm}
                    />
                </div>
                {/* Show on mobile */}
                <Hidden mdUp>
                    <Box
                        onClick={this.validateForm}
                        onMouseEnter={this.validateForm}
                        className={classes.payCta}
                    >
                        <AxleButton
                            isLoading={this.state.isPaymentLoading}
                            className={classes.payCta}
                            onClick={this.handlePaymentClick}
                            trackingEvent={{ name: 'Book' }}
                            tooltip={
                                showBookVisitBtn
                                    ? 'All fields must be completed before booking your visit'
                                    : ''
                            }
                            disabled={!this.canProceedToPayment()}
                        >
                            <Typography variant='button'>Book Visit</Typography>
                        </AxleButton>
                    </Box>
                </Hidden>
                <Snackbar
                    className={classes.snackbar}
                    open={this.state.snackbar.show}
                    autoHideDuration={5000}
                    onClose={this.handleCloseSnackbar}
                >
                    <MuiAlert
                        onClose={this.handleCloseSnackbar}
                        severity={this.state.snackbar.severity}
                    >
                        {this.state.snackbar.message}
                    </MuiAlert>
                </Snackbar>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    zipCode: state.zipCode,
    city: state.city,
    usState: state.usState,
    partnerId: state.partnerId,
    presetServices: state.presetServices,
    providerId: state.providerId,
    partnerMeta: state.partnerMeta,
    visitRequestId: state.visitRequestId,
    tier: state.tier,
});

export default withRouter(connect(mapStateToProps, null)(withStyles(styles)(Book)));
